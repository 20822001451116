import isBlank from 'is-blank'
import IMAGE_ACC from '../../../public/images/dummy.jpg'
import IMAGE_MESSAGE from '../../../public/images/image-not-found.png'

const moment = require('moment');
const { toFriendlyDateTime } = require('../date-utils');

export default {
    isBlank(val) {
        return isBlank(val)
    },
    utcToLocal(date, format) {
        var utcLocal = moment(date).local().format(format);

        return utcLocal;
    },
    createErrorFromList(errors) {
        let message = {
            type: 'danger',
            show: true,
            html: 'Network Error.',
            icon: 'fa-warning',
            timeout: 2500,
        }

        message.html = errors.map(item => {
            var el = document.createElement("li");
            el.innerText = el.textContent = item;
            return el.innerHTML;
        })

        message.html = '<ul>Terdapat kesalahan berikut:' + message.html + '</ul>'
        return message
    },
    handleImageError: function (event, type) {
        if (type == 'contact') event.target.src = IMAGE_ACC;
        if (type == 'message') event.target.src = IMAGE_MESSAGE;
    },
    createError(error) {
        //Quasar Toast Schema
        let message = {
            type: 'danger',
            show: true,
            html: 'Network Error.',
            icon: 'fa-warning',
            timeout: 2500,
        }

        if (typeof error !== 'undefined' && error != null && error.hasOwnProperty('message')) {
            message.html = error.message
        }

        if (typeof error.response !== 'undefined' && error.response != null) {
            //Setup Generic Response Messages
            message.httpStatus = error.response.status
            if (error.response.status === 401) {
                message.html = 'UnAuthorized'
                //vm.$emit('logout') //Emit Logout Event
            } else if (error.response.status === 403) {
                message.html = 'Not authorized to access resource'
            } else if (error.response.status === 404) {
                message.html = 'API Route is Missing or Undefined'
            } else if (error.response.status === 405) {
                message.html = 'API Route Method Not Allowed'
            } else if (error.response.status === 422) {
                //Validation Message
            } else if (error.response.status >= 500) {
                message.html = 'Server Error'
            }

            //Try to Use the Response Message
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                if (error.response.data.hasOwnProperty('message') && error.response.data.message.length > 0) {
                    message.html = error.response.data.message
                }
            }
        }

        return message;
        //Toast the Message
        //if (message.html.length > 0) {
        //Toast.create.negative(message)
        // }
    },
    listMessageMapper(messageList, prevMsg) {
        const messageListReversed = messageList.reverse();
        
        const mapped = messageListReversed.map((val) => {
            val.showReaction = false
            val.time = toFriendlyDateTime(val.createdOn);

            if(val.url && process.env.VUE_APP_IMAGE_ORIGINAL_URL && process.env.VUE_APP_IMAGE_CDN_URL) {
                val.cdnUrl = val.url.replace(process.env.VUE_APP_IMAGE_ORIGINAL_URL, process.env.VUE_APP_IMAGE_CDN_URL)
            }

            if (val.text) {
                val.originalText = val.text 
                val.text = this.formatTabMessages(val.text)
            }

            if (val.internalNotes) {
                val.internalNotes.forEach(note => {
                    note.time = toFriendlyDateTime(note.createdOn)
                })

                val.internalNotes = this.formatInternalNotes(val.internalNotes)
            }

            if (val.isResponseTime && val.responseTime) {
                if (val.responseTime < 60) {
                    val.convertedResponseTime = "0:" + val.responseTime
                } else {
                    const minute = Math.floor(val.responseTime / 60)
                    const seconds = val.responseTime % 60
                    val.convertedResponseTime = minute + ":" + seconds
                }
            }

            if (val.quotedMessage) {
                val.quotedMessage.text = val.quotedMessage.text ? this.formatTabMessages(val.quotedMessage.text) : ""
            }

            if (val.noteAction) {
                val.noteAction.time = toFriendlyDateTime(val.noteAction.createdOn)
            }

            if (prevMsg != null && prevMsg.from == val.from && 
                (prevMsg.from =='op' || prevMsg.from == 'op-wa') && 
                prevMsg.operatorId == val.operatorId &&
                moment(val.createdOn).subtract('seconds', 180).isBefore(prevMsg.createdOn)
            ) {
                val.showOpHeader = false;
            } else if (prevMsg != null && prevMsg.from == 'cust' && 
                prevMsg.from == val.from && 
                moment(val.createdOn).subtract('seconds', 180000).isBefore(prevMsg.createdOn)
            ) {
                if (val.isGroup && prevMsg.author.contactId != val.author.contactId) {
                    val.showOpHeader = true;
                } else {
                    val.showOpHeader = false;
                }
            } else {
                val.showOpHeader = true;
            }

            prevMsg = val;

            return val;
        });

        return mapped
    },
    changeInitialName(name) {
        let changedName = String()

        name.split(" ").forEach(e => {
            if (changedName.length < 2) changedName += e[0]
        })

        return changedName
    },
    formatInternalNotes: function (internalNotes) {
        const internalNotesComplain = internalNotes.filter(note => note.contentType == 'complain' || 
            note.contentType == 'agent' || note.contentType == 'product')

        let tempNotesWithoutComplain = []

        internalNotes.filter(note => note.contentType != 'complain' && note.contentType != 'agent' && 
            note.contentType != 'product').forEach(e => {

            if (tempNotesWithoutComplain.length < 0) {
                tempNotesWithoutComplain.push({
                    contentType: e.contentType,
                    authorId: e.authorId,
                    authorName: e.authorName,
                    list: [e]
                })
                
            } else {
                const index = tempNotesWithoutComplain.findIndex(el => el.authorId == e.authorId &&
                    el.contentType == e.contentType)

                if (index == -1) {
                    tempNotesWithoutComplain.push({
                        contentType: e.contentType,
                        authorId: e.authorId,
                        authorName: e.authorName,
                        list: [e]
                    })
                } else {
                    tempNotesWithoutComplain[index].list.push(e)
                }
            }
        })

        return [...tempNotesWithoutComplain, ...internalNotesComplain]
    },
    formatTabMessages(message) {
        let temp = message

        // check url
        if (this.stringIsUrl(message)) {
            let checkWhiteSpace = []
            let checkNewLine = []

            temp.split(" ").forEach(e => {
                if (this.stringIsUrl(e) && e.indexOf('\n') == -1) {
                    checkWhiteSpace.push(`<a href='${e}' target='_blank'>${e}</a>`)
                } else {
                    checkWhiteSpace.push(e)
                }
            })

            checkWhiteSpace.join(' ').split("\n").forEach(e => {
                if (this.stringIsUrl(e) && e.indexOf(' ') == -1) {
                    checkNewLine.push(`<a href='${e}' target='_blank'>${e}</a>`)
                } else {
                    checkNewLine.push(e)
                }
            })

            temp = checkNewLine.join('\n')
        }

        let current = true

        // check bold
        const isBold = temp.split('').includes('*')
        if (isBold) {
            temp = temp.split('').map(e => {
                if (e == '*') {
                    if (current) {
                        current = false
                        return '<span style="font-weight: bold;">'
                    } else {
                        current = true
                        return '</span>'
                    }
                }

                return e
            }).join('')
        }

        // check italic
        // const isItalic = temp.split('').includes('_')
        // if(isItalic) {
        //     current = true
        //     temp = temp.split('').map(e => {
        //         if(e == '_') {
        //             if(current) {
        //                 current = false
        //                 return '<i>'
        //             } else {
        //                 current = true
        //                 return '</i>'
        //             }
        //         }

        //         return e
        //     }).join('')
        // }

        // check Strikethrough
        const isStrikethrough = temp.split('').includes('~')
        if (isStrikethrough) {
            current = true
            temp = temp.split('').map(e => {
                if (e == '~') {
                    if (current) {
                        current = false
                        return '<s>'
                    } else {
                        current = true
                        return '</s>'
                    }
                }

                return e
            }).join('')
        }

        return temp ? temp.split('\n').join('<br />') : temp
    },
    stringIsUrl(str) {
        const isUrl = new RegExp('((http|https)://)(www.)?' +
            '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' +
            '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
        )

        return isUrl.test(str)
    },
}